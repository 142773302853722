import ContestPaymentReport from "../../../components/admin/contestPaymentReport/ContestPaymentReport"


const ContestPaymentReportPage = () => {
    return (
        <>
            <ContestPaymentReport />
        </>
    )
}

export default ContestPaymentReportPage