import DeatilsNews from "../../../components/admin/deatilsNews/DeatilsNews"


const DetailsNewsPage = () => {
    return (
        <>
            <DeatilsNews />
        </>
    )
}

export default DetailsNewsPage