import AddWithdrawRequest from "../../../../components/admin/withDraw/addWithdrawRequest/AddWithdrawRequest"


const AddWithdrawRequestPage = () => {
    return (
        <>
            <AddWithdrawRequest />
        </>
    )
}

export default AddWithdrawRequestPage