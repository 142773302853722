import AllContestList from "../../../components/admin/allContestList/AllContestList"


function AllContestListPage({ nameHeading }) {
    return (
        <>
            <AllContestList nameHeading={nameHeading} />
        </>
    )
}
export default AllContestListPage