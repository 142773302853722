
// export const baseUrl = 'https://devapi.paypanda.in/api/'
// export const baseUr2 = 'https://devapi.paypandabnk.com/api/'
// // export const baseUrl = 'http://localhost:3200/api/'

// export const baseUrlImage = 'https://devapi.paypandabnk.com/cloudinary/'


export const baseUrl = 'https://schedule.playnif50.com/api/'
export const baseUr2 = 'https://schedule.playnif50.com/api/'
// export const baseUrl = 'http://localhost:3200/api/'

export const baseUrlImage = 'https://schedule.playnif50.com/api/cloudinary/'
