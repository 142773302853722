import ListWithdrawRequests from "../../../../components/admin/withDraw/listWithdrawRequests/ListWithdrawRequests"


const ListWithdrawRequestsPage = ({ nameHeading }) => {
    return (
        <>
            <ListWithdrawRequests nameHeading={nameHeading} />
        </>
    )
}

export default ListWithdrawRequestsPage