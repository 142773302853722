import DashboardPalyNif from "../../../components/dashboardPalyNif/DashboardPalyNif"


function DashboardPalyNifPage() {
    return (
        <>
            <DashboardPalyNif />
        </>
    )
}
export default DashboardPalyNifPage