

function AdminRightHeader() {
    return (
        <>
            <div className="PageHeading">
                <h1>Paytm Wallet Topup</h1>
            </div>

        </>
    )
}
export default AdminRightHeader