
import { Navigate, Route, Routes } from 'react-router-dom';
import './asesets/main.css'
import './asesets/css/bootstap2.css'
import './asesets/css/caltmine.css'
import './asesets/css/fontAwesom.css'
// import './asesets/css/mixin.scss'

import './asesets/css/customAdmin.css'
import './asesets/css/selectMin.css'
import './asesets/css/responsive.css'
import "react-toastify/dist/ReactToastify.css";




import { useEffect, useState } from 'react';


// import PaymentGateWayPage from './pages/admin/paymentGateWay';

// ---------------------------------------------------
import PasswordChagePage from './pages/admin/settings/passwordChage';
import CreatePinPage from './pages/admin/settings/createPin/CreatePinPage';
// import OtpSecurityPage from './pages/admin/settings/otpSecurity';
import TdsCertificatePage from './pages/admin/tdsCertificate';
import MerchantLoginAreaPage from './pages/merchantLoginArea/Index';
import SinUpMerchantPage from './pages/merchantLoginArea/sinupMerchant';
import CompeleteRegister from './pages/merchantLoginArea/compeleteRegister';
import "react-toastify/dist/ReactToastify.css";
// import Shipping_Address from './pages/admin/shipping_Address';
// import FormBillAdd from './pages/admin/shipping_Address/FormBillAdd';
import BillingAddress from './pages/admin/billingAddress';
import ProfilePage from './pages/admin/profilePage';
import WalletReportPage from './pages/admin/walletReport';
import PaymentRequestViewPage from './pages/admin/payment/paymentRequestView/PaymentRequestView';
import AddPaymentRequestPage from './pages/admin/payment/addPaymentRequest';
// import PaymentRequestPage from './pages/admin/payment/paymentRequest';
// import PaymentRequestByMemberPage from './pages/admin/payment/paymentRequestByMember';
// import DaseboardCheckPage from './pages/admin/daseboardCheck';
// import ActivityLogPage from './pages/admin/activityLg';
// import DtmReportDetails from './components/admin/dtmReportSearch/dtmReportDetails/DtmReportsDetails';
import DeclearPage from './common/declearPage/DeclearPage';
// import AepsWalletsPage from './pages/admin/aepsWallets';
// import TransactionReportPage from './pages/admin/tranactionReports';
// import AepsWalletsTrasferPage from './pages/admin/aepsWalletTransfer';
// import QucickDhanPage from './pages/admin/quickDhan';
import CompanyBankDetailsPage from './pages/admin/companyBankdetails';
// import AepsOnBoardingPage from './pages/admin/aepsOnboarding';
import PrivateRoute from './pages/privateRoute/PrivateRoute';

import OpenDisputPage from './pages/admin/disput/openDisput';
// import ClossingDisputPage from './pages/admin/disput/clossingDisput';
import AddTicketPage from './pages/admin/disput/AddTicket';
import ListTicketsPage from './pages/admin/disput/listTickets';
import ListTicketUser from './components/admin/disput/listTickets/listTicketUser/ListTicketUser';
import PackageListPage from './pages/admin/package/packagelist';

import TdsCertificatee from './components/admin/tdsCentificate/TdsCertificate';
// import IdCard from './components/admin/idCard/IdCard';
import Certificate from './components/admin/cartificate/cartificate';
// import BcAuthorization from './components/admin/bcAuthorizse/tCertificate';

import PackageDetails from './components/admin/package/packageDetails/PackageDetails';
// import Commission from './components/admin/commission/Commission';
// import Balance from './components/admin/balance/Balance';
import Status from './components/admin/status/Status';
import PackageHistory from './components/admin/package/packageHistory/PackageHistory';


// import Loan from './components/loan/Loan';
// import Loanform1 from './components/loan/Loanform1';

import { getToken } from 'firebase/messaging';
import { messaging } from './firebase';
import { getNotiToken } from './components/admin/notificationSide/NotificationSide';

import Invoice from './components/admin/package/packageDetails/Invoice';
// import AepsDipositPurchge from './pages/admin/cashDiposit/aepsDipositPurchage/AepsDipositPurchage';
import { bpbsAllServiceList, UserPermissionAeps, UserPermissionAeps2, WalletsShow } from './api/login/Login';

// import Userlist from './pages/admin/userlist/Userlist';

// import UserReport from './pages/admin/userlist/Userreport';
// import Earningreport from './pages/admin/userlist/Earningreport';
// import InvoiceBill from './pages/admin/userlist/Invoice';

// import ServiceSlider from './pages/admin/userlist/ServiceSlider';
// import ServiceSlidernew from './pages/admin/userlist/ServiceSlider';
// import BPBSService from './pages/admin/userlist/BPBSService';
// import LedgerReport from './pages/admin/userlist/LedgerReport';
// import BusinessReport from './pages/admin/userlist/BusinessReport';
// import AepsInvoice from './pages/admin/userlist/AepsInvoice';
// import PayoutInvoice from './pages/admin/userlist/Payoutinvoice';
// import CmsInvoice from './pages/admin/userlist/CmdInvoice';




// import UserManegmentPage from './pages/admin/userManegement';
// import { AddStaff } from './components/admin/userManegement/addStaff/AddStaff';
// import MakePaymentPage from './pages/admin/makePayment';

import PlayNif50Page from "./pages/playNif50";
import { SelectPlayers } from "./components/playNif50/Nifty50/nifty50Slide/nifty50HourlyContest/selectPlayers/SelectPlayers";
import { SelectStock } from "./components/playNif50/Nifty50/nifty50Slide/nifty50HourlyContest/selectPlayers/selectStock/SelectStock";
import AllContestListPage from './pages/admin/allContestList';

import PaymentGateWayPage from './pages/admin/payment/paymentGatway';
import { AllContestRankDetails } from './components/admin/allContestList/allContestRankDetails/AllContestRankDetails';
import { SelfContestRankDetails } from './components/admin/allContestList/selfContestRankDetails/SelfContestRankDetails';
import DashboardPalyNifPage from './pages/admin/dashboardPalyNif';

import StaffKycPage from './pages/admin/staffKyc';
import HowToplayPage from './pages/admin/howToPlay';
import DetailsNewsPage from './pages/admin/deatilsNews';
import ContestPaymentReportPage from './pages/admin/contestPaymentReport';
import BlogPage from './pages/admin/blog';
import BlogDetails from './components/admin/blog/blogDetails/BlogDetails';

import AddWithdrawRequestPage from './pages/admin/withDraw/addWithdrawRequest';
import ListWithdrawRequestsPage from './pages/admin/withDraw/listWithdrawRequests';
import MyReferedMemberPage from './pages/admin/myReferedMember';

function App() {
  const [isLogin, setislogin] = useState(window.localStorage.getItem('login'))
  const [walletData, setWalletData] = useState()

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [services, setServices] = useState([]);
  useEffect(() => {
    window.localStorage.setItem('login', false)
  }, [])


  const [position, setPosition] = useState({ latitude: null, longitude: null });

  // console.log('position', position);
  const [tokenNoti, setokenNoti] = useState()


  async function requestPermission() {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      const token = await getToken(messaging, {
        vapidKey: "BPmnN4enu6SLX6ASW7dctK6Q0j3GnTUhL5ZRi16I6RDqGav4khN2JIHmdKcL4eTqwRBu-PWmaUa1G-Oaor7AcF4"
      });
      setokenNoti(token)
    } else if (permission === "denied") {
      alert("You denied for the notification");
    }
  }




  useEffect(() => {
    requestPermission();
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(function (position) {
        setPosition({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      });
    } else {
      alert('Geolocation is not available in your browser.')
    }
  }, []);
  // console.log("position", position)

  const walletShowHeader = async () => {
    try {
      const res = await WalletsShow()
      setWalletData(res?.data?.data);
    } catch (error) {

    }
  }

  useEffect(() => {
    // dthPermission()
    // mobileRechargePermission()
    // mobileRechargePospaidPermission()
    // electricityPermission()
    // gasPermission()
    // fastagPermission()
    // bordBandPermission()
    // landLinePermission()
    // waterPermission()
    // creditCartPermission()
    // addPayoutPermission()
    walletShowHeader()
  }, [])
  useEffect(() => {
    const fetchServices = async () => {
      try {
        // const response = await bpbsAllServiceList();
        // setServices(response.data);
      } catch (error) {
        console.error('Error fetching services:', error);
      }
    };

    fetchServices();
  }, []);

  return (
    <>
      {/* {isLogin == 'false' && <Header setislogin={setislogin} />} */}
      {/* <Header /> */}



      <Routes>

        <Route path='login-area' element={<MerchantLoginAreaPage />} />
        <Route path='Signup' element={<SinUpMerchantPage position={position} />} />
        <Route path='signup/:id' element={<SinUpMerchantPage position={position} />} />
        <Route path="/" element={<Navigate to="/login-area" />} />
        <Route path='/login-area' element={<MerchantLoginAreaPage />} />
        <Route path='/registrationComplete' element={<CompeleteRegister />} />
        <Route path='/is_self_declare' element={<DeclearPage />} />

        <Route path='/' element={<PrivateRoute tokenNoti={tokenNoti} walletData={walletData} services={services} />}>
          {/* <Route path="" element={<DasBoardPage />} > */}
          {/* <Route path='/Dashboard' element={<DaseboardCheckPage walletData={walletData} services={services} />} /> */}
          <Route path="/home/playNif50" element={<PlayNif50Page />} />
          <Route path='dashboard/playNif' element={<DashboardPalyNifPage />} />

          <Route path='wallet-report' element={<WalletReportPage />} />
          <Route path='contest-payment-report' element={<ContestPaymentReportPage />} />

          <Route path='payment-request-to-company' element={<PaymentRequestViewPage />} />
          <Route path='add-payment-request' element={<AddPaymentRequestPage />} />



          <Route path='change_password' element={<PasswordChagePage />} />

          <Route path='change_txnpassword' element={<CreatePinPage />} />

          <Route path='tds_certificate' element={<TdsCertificatePage />} />
          <Route path='profile' element={<ProfilePage />} />


          <Route path='billing_Address' element={<BillingAddress />} />
          <Route path='open-dispute' element={<OpenDisputPage />} />
          <Route path='company-bank-details' element={<CompanyBankDetailsPage />} />
          <Route path='add-ticket/:id' element={<AddTicketPage />} />
          <Route path='add-ticket' element={<AddTicketPage />} />
          <Route path='list-tickets' element={<ListTicketsPage />} />
          <Route path='list-ticket-user/:id' element={<ListTicketUser />} />
          <Route path='package-list' element={<PackageListPage />} />
          {/* <Route path='loan' element={<Loan />} /> */}
          <Route path='package-details/:id' element={<PackageDetails walletShowHeader={walletShowHeader} walletData2={walletData} />} />
          <Route path='package-history' element={<PackageHistory />} />
          <Route path='TDS-Certificate' element={<TdsCertificatee />} />
          {/* <Route path='id-card' element={<IdCard />} /> */}
          <Route path='certificate' element={<Certificate />} />

          <Route path='status' element={<Status />} />
          {/* <Route path='loan-form' element={<Loanform1 />} /> */}
          <Route path='invoice' element={<Invoice />} />





          {/* <Route path="hourly-contest" element={<Nifty50HourlyContest />} /> */}
          <Route path="select-palyers/:id" element={<SelectPlayers walletShowHeader={walletShowHeader} walletData={walletData} />} />
          <Route path="select-stock" element={<SelectStock />} />
          <Route path="all-contest-list/:name" element={<AllContestListPage nameHeading="ALL CONTEST LIST" />} />
          <Route path="my-win-contest-list/:name" element={<AllContestListPage nameHeading="My WIN CONTEST LIST" />} />
          <Route path="upcomming-Contests-List/:name" element={<AllContestListPage nameHeading="Upcomming CONTEST LIST" />} />
          <Route path="live-Contests-List/:name" element={<AllContestListPage nameHeading="Live CONTEST LIST" />} />
          <Route path="all/deatils/:id" element={<AllContestRankDetails />} />
          <Route path="self/deatils/:id" element={<SelfContestRankDetails />} />
          <Route path="payment-getway" element={<PaymentGateWayPage walletShowHeader={walletShowHeader} />} />
          <Route path="how-to-play" element={<HowToplayPage />} />

          <Route path="my_kyc" element={<StaffKycPage />} />
          <Route path="news_deatils/:id" element={<DetailsNewsPage />} />
          <Route path="blog" element={<BlogPage />} />
          <Route path="blog_news_deatils/:id" element={<BlogDetails />} />
          <Route path="add-withdraw-request" element={<AddWithdrawRequestPage />} />
          <Route path="pending-list-withdraw-request/:name" element={<ListWithdrawRequestsPage nameHeading={'LIST WITHDRAW REQUESTST PENDING'} />} />
          <Route path="approved-list-withdraw-request/:name" element={<ListWithdrawRequestsPage nameHeading={'LIST WITHDRAW REQUESTST Approved'} />} />
          <Route path="rejected-list-withdraw-request/:name" element={<ListWithdrawRequestsPage nameHeading={'LIST WITHDRAW REQUESTST Rejected'} />} />
          <Route path="all-list-withdraw-request/:name" element={<ListWithdrawRequestsPage nameHeading={'LIST WITHDRAW REQUESTST All'} />} />
          <Route path="my-referred-member" element={<MyReferedMemberPage />} />
        </Route>
        {/* <Route /> */}
      </Routes>
      {/* {isLogin == 'false' && <Footer />} */}
      {/* <div className='LayoutMain'>
      </div> */}
    </>
  );
}

export default App;
