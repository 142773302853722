

import Profile from "../../../components/admin/profilePage/Profile"


function ProfilePage() {
    return (
        <>
            <Profile />
        </>
    )
}
export default ProfilePage