import MyReferedMember from "../../../components/admin/myReferedMember/MyReferedMember"


const MyReferedMemberPage = () => {
    return (
        <>
            <MyReferedMember />
        </>
    )
}

export default MyReferedMemberPage