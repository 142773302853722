import StaffKyc from "../../../components/admin/staffKyc/StaffKyc"


function StaffKycPage() {
    return (
        <>
            <StaffKyc />
        </>
    )
}
export default StaffKycPage