export const TopSection = () => {

    return (
        <div className="login-area-regis">
            <h4>Complete Your KYC</h4>
            <div>
                <p>
                    You are just a few steps away from embarking on an exciting financial journey with us.
                </p>
            </div>
        </div>
    );
}
